// BUTTONS
// ---------------------------


.button_reset{
  display: inline-block;
  appearance: none;
  border: 1px solid transparent;
  background-color: transparent;
  white-space: nowrap;
  text-align: center;
  line-height: 1.41;
  cursor: default;
  transition: all .3s;

  &[href]{
    text-decoration: none;
  }
}

.button-action{
  color: white;
  @extend .button_reset;
  font-weight: 700;
  padding: 6px 21px;
  @include rem(13);
  border-radius: 16px;

  &_large{
    font-family: $font-button;
    border-radius: 23px;
    text-transform: uppercase;
    @include rem(15);
    padding: 11px 25px;
  }

  &_danger{
    background-color: $color-button-action;
  }

  &_primary{
    background-color: $color-highlight;
  }

  &_default{
    background-color: $color-button-disabled;
  }
}

a,
input[type='submit'],
button{

  &.button-rounded,
  &.button-action{
    cursor: pointer;
  }

  &.button-action_primary,
  &.button-rounded_primary{
    &:hover{
      background-color: lighten($color-highlight, 10%);
    }
  }

  &.button-action_secondary,
  &.button-rounded_secondary{
    &:hover{
      background-color: lighten($color-button-secondary, 10%);
    }
  }

  &.button-action_danger,
  &.button-rounded_danger{
    &:hover{
      background-color: lighten($color-button-action, 10%);
    }
  }

  &.button-action_success,
  &.button-rounded_success{
    &:hover{
      background-color: lighten($color-button-success, 10%);
    }
  }

  &.button-rounded_glass{
    &:hover{
      background-color: #fff;
      color: $color-highlight;
    }
  }
}

// Закругленные кнопки
.button-rounded{
  @extend .button_reset;
  color: white;
  font-family: $font-main;
  padding: 5px 10px;
  border-radius: 4px;
  @include rem(14);
  font-weight: 400;

  &_large{
    border-radius: 5px;
    padding: 9px 25px;
    font-weight: 700;
    @include rem(18);
  }

  &_primary{
    background-color: $color-highlight;
  }

  &_secondary{
    background-color: $color-button-secondary;
  }

  &_glass{
    border: 1px solid white;
  }

  &_transparent{
    color: $color-button-default;
    border-color: $color-button-default;
  }

  &_success{
    background-color: $color-button-success;
  }
}


.button-size{
  width: 100%;
  border: 1px solid $color-border-block;
  @include rem(15);
  max-width: 60px;
  text-align: center;
  padding: 3px ;
  border-radius: 14px;
  margin-bottom: 13px;
}


button,
a[href]{
    &.button-rounded,
    &.button-action{
      cursor: pointer;
    }
}
