@keyframes slideInDown {
  0% {
    -webkit-transform:translate3d(0,-100%,0);
    transform:translate3d(0,-100%,0);
    visibility:visible
    }
  100%{
    -webkit-transform:translate3d(0,0,0);
    transform:translate3d(0,0,0)
  }
}


.slideInDown{
  -webkit-animation-name: slideInDown;
          animation-name: slideInDown;
}

.animated{
  -webkit-animation-duration: .5s;
          animation-duration: .5s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
}


.element-invisible{
  display: none;
}

// ЕБАНЫЙ ДРУПАЛ
// ЕБАНЫЙ ДРУПАЛ
// ЕБАНЫЙ ДРУПАЛ
// ЕБАНЫЙ ДРУПАЛ
.contextual-links-region{

  .primary-menu & {
    position: static;
  }
}

.contextual-links{
  @include rem(12);
  font-weight: 400;
}


.tabs{
  list-style-type: none;
  margin: 0 -5px;
  padding: 0;

  &__item{
    display: inline-block;
    margin: 0 5px 20px;
  }
}

.messages{
  margin: 0 -20px;
  padding: 20px 20px;
  background-color: $color-primary-menu;
  margin-bottom: 20px;
  display: inline-block;

  ul{
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  a{
    color: $color-highlight;
  }

  &.status{

  }
}

#slide-1{
  background-image: linear-gradient(45deg, #2d9c67 0%, #69da79 100%);;
}

#slide-2{
  background-image: linear-gradient(175deg, #d4d9dd 0%, #f6fbfd 60%, #fff 80%);

  .slider-item{
    padding-top: 75px;
  }
}

.bg-img-slide{
  position: absolute;
  top: 84px;
  right: 0px;
  z-index: -1;

  &_slide-3{
    top: 79px;
    right: -10px;
  }
}
