// list pages
// ----------

.paging{
  $color-color: #2d2f35;
  position: fixed;
  top: 0;
  left: -270px;
  width: 270px;
  bottom: 0;
  background-color: $color-color;
  font-size: 12px;
  display: flex;
  flex-flow: row-reverse nowrap;
  align-items: stretch;
  transition: all .3s;
  z-index: 9999;

  &.open{
    left: 0;
  }

  .rotate{
    .icon-padding{
      transform:  rotate(180deg);
    }
  }

  &__action{
    background-color: lighten(#2d2f35, 5%);
    border: none;
    width: 30px;
    position: absolute;
    padding: 30px 0;
    border-radius: 0 5px 5px 0;
    top: 30px;
    right: -30px;
    transition: background-color .3s;
    transition: right .3s;

    .icon-padding{
      transition: transform .2s;

      &:before{
        content: '';
        display: inline-block;
        height: 0;
        width: 0;
        border: 5px solid transparent;
        border-width: 5px 0 5px 6px;
        border-left-color: white;
      }
    }
    &:hover{
      background-color: lighten($color-color, 10%);
    }
    &:focus{
      outline: none;
    }
  }

  .opening{
    right: 0;
    z-index: 99;
  }



  &__list{
    flex-grow: 1;
    width: 270px;
    flex-basis: 270px;
    padding: 30px 0;
    margin: 0;
    overflow: auto;
    position: relative;
    z-index: 100;
    list-style-type: none;
    background-color: $color-color;
  }

  &__link{
    display: block;
    text-transform: uppercase;
    padding: 10px 20px;
    color: white;
    text-decoration: none;
    color: white;

    &:hover{
      background-color: lighten($color-color, 5%);
      color: white;
    }
  }
}
