// All blocks styles inhere
// -----------------------------

.primary-menu{
  position: relative;
  z-index: 1;

  &__in{
    margin-left: -20px;
    margin-right: -20px;
  }
}

.menu{
  list-style-type: none;
  font-size: 0;
  margin: 0;
  padding-left: 0;

  .primary-menu__in > & {
    display: flex;
    flex-flow: row nowrap;
  }

  // primary menu
  .primary-menu &{

    &__item{
      transition: background .3s, color .3s, border .3s;
      color: $color-text-main;
      @include rem(15);

      &_depth-1{
        padding: 14px 0 14px;
        flex: 2 0 auto;
        border-bottom: 3px solid transparent;

        &:first-child,
        &:last-child{
          flex: 1 0 auto;
        }

        &:first-child{
          .menu__link_depth-1{
            padding-left: 20px;
            text-align: left;
          }
        }

        &:last-child{
          .menu__link_depth-1{
            padding-right: 20px;
            text-align: right;
          }
        }


        &:nth-last-child(2){
          color: $color-highlight;
        }

        &:last-child{
          color: $color-button-action;
        }

        &:hover{
          background-color: #fff;
          border-bottom: 3px solid $color-highlight;
          color: $color-highlight;

          &:after{
            visibility: hidden;
          }
        }

        > .menu{
          left: 0;
          right: 0;
          padding-left: calc(50% - 470px);
          padding-right: calc(50% - 470px);
          box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
        }

        &:hover{
          & > .menu{
            display: flex;
            flex-flow: row nowrap;
          }
        }
      }

      &_depth-2{
        padding: 9px 0;
        align-items: center;
        position: relative;
        flex: 1 1 auto;

        > .menu{
          left: -20px;
          right: 0;
        }

        &:hover{
          color: $color-highlight;

          > .menu{
            display: block;
          }
        }
      }

      &_depth-1,
      &_depth-2{
        display: inline-flex;
        flex-flow: row nowrap;
      }

      & > .menu{
        display: none;
        position: absolute;
        background-color: #fff;
        top: 100%;
      }

      &_depth-2:not(:last-child):not(.expanded){
        &:after{
          margin: 0 22px;
        }
      }

      &_depth-2.expanded{
        &:after{
          content: '';
          display: block;
          position: relative;
          top: 2px;
          width: 12px;
          height: 8px;
          margin-left: 8px;
          margin-right: 21px;
          background-image: url('../images/dropdown-arrow.svg#base64');
          background-repeat: no-repeat;
          background-position: center;
          background-color: contain;
        }

        &:hover{
          &:after{
            background-image: url('../images/dropdown-arrow-highlight.svg#base64');
          }
        }
      }

      &_depth-1,
      &_depth-2:not(.expanded){
        &:not(:last-child){
          &:after{
            flex: 0 0 auto;
            content: '|';
            font-size: 17px;
            color: $color-delimiter;
            //display: inline-block;
            width: 0;
          }
        }
      }

      &_depth-3{
        display: block;
        white-space: normal;
      }
    }

    &__link{
      display: block;
      @include rem(17);
      text-decoration: none;
      font-weight: 700;
      color: inherit;

      &_depth-1{
        flex: 1 0 auto;
        text-align-last: auto;
        text-align: left;
      }

      &_depth-2{
        @include rem(15);
        font-weight: 400;
      }

      &_depth-3{
        padding: 10px 20px;
        font-weight: 400;
        transition: all .3s;
        @include rem(14);

        &:hover{
          background-color: $color-highlight;
          color: white;
        }
      }

      &_depth-1,
      &_depth-2{
        text-align: center;
        white-space: nowrap;
      }
    }
  }


  // Footer menu
  .footer-menu &{
    color: black;
    padding-left: 0;

    &__item{
      @include rem(14);
    }

    &__link{
      color: inherit;
      line-height: 1.61;

      &:hover{
        text-decoration: none;
      }
    }
  }

  // FOOTER TOP MENU
  .footer-top-menu &{
    padding: 50px 0 45px;
    text-align: center;

    &__item{
      display: inline-block;
      @include rem(15);
    }

    &__link{
      display: block;
      padding-left: 20px;
      padding-right: 20px;
      color: inherit;
      font-weight: 700;
      text-decoration: underline;

      &:hover{
        text-decoration: none;
      }
    }
  }
}

.logo{
  svg{
  }
}

.block-phone{
  @include rem(21);
  margin-right: 21px;
  font-weight: 700;

  a{
    color: white;
    text-decoration: none;
  }
}

.block-simple-links{
  padding-top: 2px;
  padding-right: 20px;
  color: white;
  a{
    color: inherit;
    text-decoration: underline;

    &:hover{
      text-decoration: none;
    }
  }
}

.block-worktime{
  padding-top: 2px;
}

.worktime{
  padding-left: 16px;
  color: $color-text-secondary;
  &__icon{
    margin-top: 3px;
    float: left;
    margin-left: -16px;
    height: 12px;
    width: 12px;
  }
}

.block-cart{
  margin-left: 40px;

  a{
    color: inherit;
    text-decoration: none;
  }

  &__icon{
    position: relative;
    top: 3px;
    margin-right: 11px;
  }

  &__button{
    padding: 9px 20px;
    text-transform: initial;
    display: inline-flex;
    flex-flow: row nowrap;
    align-items: center;
    vertical-align: middle;

    .block-cart__icon{
      position: static;
    }

    + .block-cart__link{
      color: $color-text-secondary;
      margin-left: 10px;
      position: relative;
      top: 2px;
    }
  }

  &__link + .block-cart__link{
    color: $color-text-secondary;

    &:before{
      content:  'l';
      margin-left: 10px;
      margin-right: 10px;
    }
  }
}

.footer-contacts{
  color: black;
  margin-bottom: 26px;

  &__worktime{

  }

  a{
    text-decoration: none;
  }
}

.sct{
  a{
    color: inherit;
    text-decoration: underline;

    &:hover{
      text-decoration: none;
    }
  }
}


.footer-logo{
  padding-top: 3px;
  padding-left: 11px;

  &__image{
    margin-bottom: 10px;
  }

  &__description{
    @include rem(13);
    padding-left: 10px;
    color: black;
  }
}

.highlight-block{
  background-image: repeating-linear-gradient(135deg,rgba(181,227,242,1) 0%,rgba(181,227,242,1) 7%,rgba(181,227,242,0) 7%,  rgba(181,227,242,0) 15%);
  text-align: center;
  padding: 23px 0;

  &__title{
    display: inline-block;
    margin-right: 40px;
    margin-bottom: 0;
    @include rem(28);
  }
}

.block-tiles{
  color: $color-text-main;
  margin-bottom: 50px;

  &__item{
    padding: 0;
  }
}

.tile{
  padding: 20px 27px 0 37px;
  z-index: 4;
  position: relative;
  height: 300px;

  &__header{
    //margin-bottom: 75px;
  }

  &__content{
    position: absolute;
    bottom: 39px;
  }

  &__action{
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
  }

  &__price{
    display: inline-block;
    margin-right: 12px;
    @include rem(28);
    font-weight: 600;
    flex: 0 0 auto;

    .tile_light &{
      color: white;
    }
  }

  &__title{
    display: block;
    display: -webkit-box;
    max-width: 265px;
    font-weight: 700;
    @include rem(28);
    margin-bottom: 28px;
    max-height: 132px;
    overflow: hidden;
    line-height: 1.21;
    color: inherit;
    text-decoration: none;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;

    .tile_light &{
      color: white;
    }
  }

  &__button{

    &_action{
      flex: 0 0 auto;
    }

    &_right{
      float: right;
    }
  }

  img{
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;

  }
}

.seo{
  width: 820px;
  margin: 0 auto 50px;
  height: 190px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $color-seo-block;
  color: $color-seo-text;
  padding: 20px 40px;
}

.tiles-features{
  background-color: $color-highlight-section;
  padding-top: 36px;
  padding-bottom: 39px;

  &_margin{
    margin-bottom: 50px;
  }
}

.tile-feature{
  padding-left: 60px;
  position: relative;

  &__icon{
     position: absolute;
     top: 0;
     left: 0;
  }
}


.slider-item{
  padding-top: 140px;
  position: relative;
}

.breadcrumbs{
  padding-top: 26px;
  padding-bottom: 14px;
  @include rem(13);
  font-weight: 400;
  color: #777;

  &__delimiter{
    margin-left: 3px;
    margin-right: 3px;
  }

  a{
    color: currentColor
  }
}

.store-item{
  border: 1px solid $color-border-block;
  position: relative;
  color: black;
  margin-bottom: 20px;

  a{
    text-decoration: none;
  }

  img{
    display: block;
    width: 100%;
    height: auto;
  }

  &__image{
  }

  &__flags{
    padding: 20px 17px 0;
    position: absolute;
  }

  &__title{
    display: block;
    @include rem(18);
    font-weight: 600;
    text-align: center;
    margin-bottom: 15px;
    line-height: 1.2;
    height: 42px;
    overflow: hidden;
    color: currentColor;
  }

  &__content{
    height: 130px;
    padding: 14px 17px;
  }

  &__actions{
    overflow: hidden;
  }

  &__price{
    @include rem(22);
    font-weight: 600;
  }

  &__button{
    float: right;
  }
}


.offers{
  margin-bottom: 70px;

  &__content{
    margin-bottom: -20px;
  }
}

.gallery{
  position: relative;
  font-size: 0;

  .active{
    border: $color-text-main solid 1px;
  }

  &__in{
    margin: 0 -5px;
  }

  &__image{
    display: inline-block;
    border: solid 1px $color-border-block;
    height: 60px;
    width: 60px;
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom: 10px;

    img{
      display: block;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }


    &_large{
      display: block;
      width: 430px;
      margin-bottom: 20px;
      height: 370px;

      img{
      }
    }
  }
}

.card-content-header{
  &__item{
    margin-right: 15px;
  }
}

.features-list{
  line-height: 1.71;
  &__title{
    float: left;
  }
  margin-bottom: 25px;
}

.product-price{
  background-color: $color-highlight-section-secondary;
  color: black;
  padding: 18px 30px;

  &__row{
    justify-content: space-between;
    overflow: hidden;
    display: flex;
    align-items: center;
    flex-flow: row nowrap;
  }

  &__label{
    @include rem(16);
  }

  &__sublabel{
    margin-top: 8px;
    line-height: .7;
  }

  &__value{
    @include rem(36);
    font-weight: 600;
  }

  &_for-boxes{
    display: block;

    .product-price__row{
      justify-content: flex-start;
    }

    .product-price__row:not(:first-child){
      padding-top: 10px;
    }


    .product-price__label{
      flex: 0 0 155px;
      width: 155px;
    }
  }
}


.table-sizes{
  border-top: 1px dashed black;
  width: 100%;
  color: black;
  @include rem(14);
  margin-bottom: 70px;

  &_row-dashed{
    tbody{

      tr:not(:first-child) .table-sizes__cell{
        padding-top: 25px;
      }

      .table-sizes__cell{
        border-bottom: 1px dashed black;
        padding-bottom: 25px;
      }
    }
  }


  &_coupe-dashed{
    margin-bottom: 25px;

    tbody:first-child tr:first-child{
      border-top: 1px dashed black;
    }

    tbody{
      border-bottom: 1px dashed black;

      &:not(:first-of-type) tr:first-child{
        .table-sizes__cell{
          padding-top: 30px;
        }
      }

      tr:last-child{
        .table-sizes__cell{
          padding-bottom: 25px;
        }
      }
    }
  }

  &_margin{
    margin-bottom: -1px;
  }

  th{
    padding-top: 25px;
    padding-bottom: 25px;
    text-align: left;
  }

  &__cell{
    vertical-align: baseline;

    &:not(:first-child){
      white-space: nowrap;
    }

    &_vat{
      vertical-align: top;
    }

    &_close{
      //vertical-align: middle;
      position: relative;
      top: 5px;
    }

    &_first{
      padding-left: 0;
      padding-top: 5px;
      padding-right: 20px;
    }
  }
}

.store{
  padding-bottom: 70px;
}

.pager{
  text-align: center;

  &__list{
    display: inline-block;
    list-style-type: none;
    font-size: 0;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding-left: 0;
    margin: 10px 0 0 !important;
  }

  &__item{
    display: inline-block;
    @include rem(14)
    height: 32px;
    width: 32px;
    line-height: 32px;
    text-align: center;

    + .pager__item{
      border-left: 1px solid #ddd;
    }

    &_current{
      background-color: $color-pager-active;
    }
  }

  a{
    display: block;
    width: 100%;
    height: 100%;
    text-decoration: none;
    color: $color-button-primary;

    &:hover{
      background-color: $color-pager-active;
    }
  }
}


.sct-spinner{
  width: 30px;
  height: 27px;
  line-height: 26px;
  text-align: center;
  border: 1px solid $color-border-block;

  &__btn{
    @extend .button_reset;
    position: absolute;
    background: url(../images/icon-arr-right.svg#base64) no-repeat;
    background-position: center;
    background-size: contain;
    height: 12px;
    width: 8px;
    top: 7px;
    right: 0;
    transition: all .3s;
    cursor: pointer;

    &:focus{
      outline: none;
    }

    &:active{
      right: -5px;
    }


    &_down{
      left: 0;
      right: auto;
      background-image: url(../images/icon-arr-left.svg#base64);

      &:active{
        right: auto;
        left: -5px;
      }
    }
  }
}

.js-spinner-wrapper{
  position: relative;
  text-align: center;
  max-width: 60px;
  margin-bottom: 15px;
}

.form-description{
  @include rem(16)
  margin-bottom: 45px;

  p{
    line-height: 1.5em;
  }
}

.form-label{
  @include rem(16);
  font-weight: 700;
  color: black;
  width: 170px;
  padding: 6px 0;
  float: left;

  .form-text{
    margin-left: 170px;
  }
}

.form-required{
  color: #c1001f;

  &_cart{
    font-weight: 400;
  }
}

.form-item{
  margin-bottom: 25px;
  &:last-of-type{
    margin-bottom: 0;
  }
}

.form{
  margin-bottom: 85px;

  &__action{
    padding-top: 25px;
    margin-left: 170px;

    &_description{
      padding-top: 0;
    }
  }

}

.label-description{
  padding: 12px 0 15px;
}

.form-text{
  border: 1px solid #bcbcbc;
  background-color: #fff;
  height: 34px;
  line-height: 34px;
  max-width: 340px;
  width: 100%;
  border-radius: 3px;
  padding: 0 15px;

  &_textarea{
    height: 115px;
    padding: 10px 15px;
    line-height: 1.45;
    max-width: 380px;
  }
}

.big-slider{
  height: 560px;
  background-color: #ced3d6;
  a{
    text-decoration: none;
  }

  img{
    display: block;
    max-width: 100%;
  }

  &__item{
    background-color: #ced3d6;
    height: 560px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
}

.catalog-groups{
  margin-bottom: 70px;

  &__in{
    margin-bottom: -10px;
  }
}

.catalog-group{
  position: relative;
  margin-bottom: 20px;

  a{
    text-decoration: none;
    color: inherit;
  }

  &__image{
    width: 100%;
    height: auto;
    max-height: 290px;
  }

  &__text{
    padding: 7px 20px;
    color: white;
    @include rem(28);
    font-weight: 700;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    min-height: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.25);
    transition: all .3s;

    a:hover &{
      background-color:rgba(0, 0, 0, .4);
    }


    &:before{
      content: '';
      display: block;
      height: 1px;
      background-color: white;
      position: absolute;
      top: 0;
      left: 15%;
      right: 15%;
    }
  }
}


.season-group{
  color: white;
  background-color: $color-text-main;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 300px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;


  &__link{
    margin-top: 90px;
    color: inherit;
    text-decoration: none;
    @include rem(28);
    font-weight: 700;
    border: 1px solid #fff;
    padding: 11px 15px;
    margin-bottom: 15px;
    transition: all .3s;

    &:hover{
      background-color: white;
      color: $color-text-main;
    }
  }

  &_winter{
    background-image: url(../images/season-1.jpg);
  }

  &_spring{
    background-image: url(../images/season-2.jpg);
  }

  &_summer{
    background-image: url(../images/season-3.jpg);
  }

  &_hunting{
    background-image: url(../images/season-4.jpg);
  }

  &__icon{
    display: inline-block;
  }
}

.sum{
  padding-top: 17px;
  margin-bottom: 70px;

  &__value{
    padding-right: 6px;
    padding-bottom: 20px;
    float: right;
  }

  &__actions{
    padding-right: 20px;
  }

  &__description{
    padding-top: 15px;
  }

  &__actions{
    clear: both;
    text-align: right;
  }
}

.checkout-data{
  padding: 30px 0 18px;
  margin-bottom: 39px;
  clear: both;
  border-top: 1px dashed black;
  border-bottom: 1px dashed black;

  &__label{
    @extend .form-label;
    width: 180px;
  }

  &__value{
    padding: 9px 0 6px;
    margin-left: 180px;
    font-family: $font-secondary;
    color: $color-breadcrumbs;
  }
}

.modal{
  background-color: #fff;
  padding: 16px 30px 30px;

  &_sizes{
    width: 580px;
  }

  &_messages{
    width: 370px;
  }
}

.links{
  margin: 0 -5px;
  @extend .clearfix;
  &__item{
    float: left;
    padding: 0 5px;
  }
}


.modal-sizes{
  @include rem(15);
  //max-width: 525px;
  //width: 100%;

  tr{
    border-bottom: 1px dashed black;
  }

  td{
    padding-top: 12px;
    padding-bottom: 12px;
    width: 62px;
  }

  &__title{
    font-weight: 700;
    width: 122px;
    padding-right: 20px;
  }
}

.hidden{
  display: none;
}
