//
//.owl-carousel {
//  display: none;
//  width: 100%;
//  -webkit-tap-highlight-color: transparent;
//  /* position relative and z-index fix webkit rendering fonts issue */
//  position: relative;
//  z-index: 1; }
//  .owl-carousel .owl-stage {
//    position: relative;
//    -ms-touch-action: pan-Y; }
//  .owl-carousel .owl-stage:after {
//    content: ".";
//    display: block;
//    clear: both;
//    visibility: hidden;
//    line-height: 0;
//    height: 0; }
//  .owl-carousel .owl-stage-outer {
//    position: relative;
//    overflow: hidden;
//    /* fix for flashing background */
//    -webkit-transform: translate3d(0px, 0px, 0px); }
//  .owl-carousel .owl-item {
//    position: relative;
//    min-height: 1px;
//    float: left;
//    -webkit-backface-visibility: hidden;
//    -webkit-tap-highlight-color: transparent;
//    -webkit-touch-callout: none; }
//  .owl-carousel .owl-item img {
//    display: block;
//    width: 100%;
//    -webkit-transform-style: preserve-3d; }
//  .owl-carousel .owl-nav.disabled,
//  .owl-carousel .owl-dots.disabled {
//    display: none; }
//  .owl-carousel .owl-nav .owl-prev,
//  .owl-carousel .owl-nav .owl-next,
//  .owl-carousel .owl-dot {
//    cursor: pointer;
//    cursor: hand;
//    -webkit-user-select: none;
//    -khtml-user-select: none;
//    -moz-user-select: none;
//    -ms-user-select: none;
//    user-select: none; }
//  .owl-carousel.owl-loaded {
//    display: block; }
//  .owl-carousel.owl-loading {
//    opacity: 0;
//    display: block; }
//  .owl-carousel.owl-hidden {
//    opacity: 0; }
//  .owl-carousel.owl-refresh .owl-item {
//    display: none; }
//  .owl-carousel.owl-drag .owl-item {
//    -webkit-user-select: none;
//    -moz-user-select: none;
//    -ms-user-select: none;
//    user-select: none; }
//  .owl-carousel.owl-grab {
//    cursor: move;
//    cursor: grab; }
//  .owl-carousel.owl-rtl {
//    direction: rtl; }
//  .owl-carousel.owl-rtl .owl-item {
//    float: right; }
//
///* No Js */
//.no-js .owl-carousel {
//  display: block; }
//
///*
// *  Owl Carousel - Animate Plugin
// */
//.owl-carousel .animated {
//  animation-duration: 1000ms;
//  animation-fill-mode: both; }
//
//.owl-carousel .owl-animated-in {
//  z-index: 0; }
//
//.owl-carousel .owl-animated-out {
//  z-index: 1; }
//
//.owl-carousel .fadeOut {
//  animation-name: fadeOut; }
//
//@keyframes fadeOut {
//  0% {
//    opacity: 1; }
//  100% {
//    opacity: 0; } }
//
///*
// * 	Owl Carousel - Auto Height Plugin
// */
//.owl-height {
//  transition: height 500ms ease-in-out; }
//
///*
// * 	Owl Carousel - Lazy Load Plugin
// */
//.owl-carousel .owl-item .owl-lazy {
//  opacity: 0;
//  transition: opacity 400ms ease; }
//
//.owl-carousel .owl-item img.owl-lazy {
//  transform-style: preserve-3d; }
//
///*
// * 	Owl Carousel - Video Plugin
// */
//.owl-carousel .owl-video-wrapper {
//  position: relative;
//  height: 100%;
//  background: #000; }
//
//.owl-carousel .owl-video-play-icon {
//  position: absolute;
//  height: 80px;
//  width: 80px;
//  left: 50%;
//  top: 50%;
//  margin-left: -40px;
//  margin-top: -40px;
//  background: url("owl.video.play.png") no-repeat;
//  cursor: pointer;
//  z-index: 1;
//  -webkit-backface-visibility: hidden;
//  transition: transform 100ms ease; }
//
//.owl-carousel .owl-video-play-icon:hover {
//  -ms-transform: scale(1.3, 1.3);
//      transform: scale(1.3, 1.3); }
//
//.owl-carousel .owl-video-playing .owl-video-tn,
//.owl-carousel .owl-video-playing .owl-video-play-icon {
//  display: none; }
//
//.owl-carousel .owl-video-tn {
//  opacity: 0;
//  height: 100%;
//  background-position: center center;
//  background-repeat: no-repeat;
//  background-size: contain;
//  transition: opacity 400ms ease; }
//
//.owl-carousel .owl-video-frame {
//  position: relative;
//  z-index: 1;
//  height: 100%;
//  width: 100%; }

/* Slider */
.slick-slider
{
    position: relative;

    display: block;
    box-sizing: border-box;

    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;

    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
        touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}

.slick-list
{
    position: relative;

    display: block;
    overflow: hidden;

    margin: 0;
    padding: 0;
}
.slick-list:focus
{
    outline: none;
}
.slick-list.dragging
{
    cursor: pointer;
    cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list
{
    -webkit-transform: translate3d(0, 0, 0);
       -moz-transform: translate3d(0, 0, 0);
        -ms-transform: translate3d(0, 0, 0);
         -o-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
}

.slick-track
{
    position: relative;
    top: 0;
    left: 0;

    display: block;
}
.slick-track:before,
.slick-track:after
{
    display: table;

    content: '';
}
.slick-track:after
{
    clear: both;
}
.slick-loading .slick-track
{
    visibility: hidden;
}

.slick-slide
{
    display: none;
    float: left;

    height: 100%;
    min-height: 1px;
}
[dir='rtl'] .slick-slide
{
    float: right;
}
.slick-slide img
{
    display: block;
}
.slick-slide.slick-loading img
{
    display: none;
}
.slick-slide.dragging img
{
    pointer-events: none;
}
.slick-initialized .slick-slide
{
    display: block;
}
.slick-loading .slick-slide
{
    visibility: hidden;
}
.slick-vertical .slick-slide
{
    display: block;

    height: auto;

    border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
    display: none;
}
