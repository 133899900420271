
// DEFINE GRID
// -----------------------

$susy: (
  columns: 12,
  output: isolate,
  gutter-position: inside,
  container-position: center,
  gutter-override: no-gutters,
  global-box-sizing: border-box,
  debug: (
    image: hide,
    color: rgba(#66f, .25),
    output: background,
    toggle: top right,
    )
  );


.sct-container{
  @include container(980px);
  width: 980px;
  padding-left: 20px;
  padding-right: 20px;

  &_clean{
    padding-left: 0;
    padding-right: 0;
  }
}

$get-columns: map-get($susy, "columns");

@for $i from 1 through $get-columns{
  .sct-col-#{$i}{
    @include span( $i );
    min-height: 1px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .sct-col-width-#{$i}{
    width: 100% / 12 * $i;
    padding-left: 10px;
    padding-right: 10px;
  }
}

@for $i from 1 through 5{
  .sct-col-#{$i}-5{
    float: left;
    width: 100% / 5 * $i;
    min-height: 1px;
    padding-left: 10px;
    padding-right: 10px;
  }
}

.sct-row{
  margin-left: -10px;
  margin-right: -10px;
  @extend .clearfix;
}


.test-grid{
  overflow: hidden;
  &__item{
    height: 50px;
    line-height: 48px;
    font-weight: 400;
    background-color: #ccc;
    border: 1px solid darken(#ddd, 20%);
    margin-bottom: 10px;
    text-align: center;
    color: darken(#ddd, 50%);
    @include rem(12);
  }
}

.zero-padding{
  padding: 0;
}

// BASE STYLES
// -----------------------

html,
body{
  height: 100%;
}

body{
  color: $color-text-main;
}



// CUDDLED FOOTER
// -----------------------

.page-wrapper{
  min-height: 100%;
  display: flex;
  min-width: 980px;
  flex-flow: column nowrap;

  > header,
  > footer,
  > div{
    flex: 0 0 auto;
  }
  .main-container{
    flex: 1 0 auto;
  }
}

.page-header-highlight{
  padding-top: 25px;
  padding-bottom: 9px;
  background-color: $color-highlight;
  color: white;
}

.primary-menu{
  background-color: $color-primary-menu;
}

.header-highlight-right,
.header-highlight-left{
  display: flex;
  height: 50px;
  flex-flow: row nowrap;
  align-items: center;
}

.header-highlight-right{
  flex-flow: row-reverse;
}

.page-footer__in{
  border-top: 1px solid $color-border-block;
  padding-top: 21px;
  padding-bottom: 65px;
}


.tech-block{
  margin-bottom: 20px;
  &_inverse{
    padding: 20px;
    background-color: $color-text-main;
  }
}

.product-page{
  &__block{
    margin-bottom: 50px;
  }
}

.hidden-container{
  display: none;
}
