//@font-face {
//  font-family: 'Source Sans Pro';
//  src: url('webfont.eot'); /* IE9 Compat Modes */
//  src: url('webfont.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
//       url('webfont.woff2') format('woff2'), /* Super Modern Browsers */
//       url('webfont.woff') format('woff'), /* Pretty Modern Browsers */
//       url('webfont.ttf')  format('truetype'), /* Safari, Android, iOS */
//       url('webfont.svg#svgFontName') format('svg'); /* Legacy iOS */
//}
@font-face {
  font-family: 'Source Sans Pro';
  src: url('../fonts/SourceSansPro-Bold.woff') format('woff'); /* Pretty Modern Browsers */
  font-style: normal;
  font-weight: 700;
}
@font-face {
  font-family: 'Source Sans Pro';
  src: url('../fonts/SourceSansPro-Semibold.woff') format('woff'); /* Pretty Modern Browsers */
  font-style: normal;
  font-weight: 600;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url('../fonts/SourceSansPro-Regular.woff') format('woff'); /* Pretty Modern Browsers */
  font-style: normal;
  font-weight: 400;
}
