// ICONS
// ----------------------------------

%icon{
  display: inline-block;
  height: 20px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-color: transparent;
  width: 20px;
}

.icon-clock{
  @extend %icon;
  background-image: url(../images/icon-clock.svg#base64);
}

.icon-cart{
  @extend %icon;
  background-image: url(../images/icon-cart.svg#base64);
}

.icon-24{
  @extend %icon;
  background-image: url(../images/icon-24.svg#base64);
  width: 40px;
  height: 40px;
}

.icon-box{
  @extend %icon;
  background-image: url(../images/icon-box.svg#base64);
  width: 41px;
  height: 39px;
}

.icon-checklist{
  @extend %icon;
  background-image: url(../images/icon-checklist.svg#base64);
  width: 40px;
  height: 48px;
}

.icon-credit-card{
  @extend %icon;
  background-image: url(../images/icon-card.svg#base64);
  width: 40px;
  height: 36px;
}


.icon-hook{
  @extend %icon;
  background-image: url(../images/icon-hook.svg#base64);
  width: 47px;
  height: 72px;
}

.icon-tree{
  @extend %icon;
  background-image: url(../images/icon-tree.svg#base64);
  width: 73px;
  height: 78px;
}

.icon-sun{
  @extend %icon;
  background-image: url(../images/icon-sun.svg#base64);
  height: 73px;
  width: 73px;
}

.icon-snowflake{
  @extend %icon;
  background-image: url(../images/icon-snowflakes.svg#base64);
  height: 74px;
  width: 64px;
}


.icon-close{
  appearance: none;
  text-decoration: none;
  text-indent: -9999px;
  border: none;
  background-color: $color-button-action;
  border-radius: 50%;
  background-position: center;
  background-image: url(../images/close-icon.svg#base64);
  background-repeat: no-repeat;
  height: 24px;
  width: 24px;

  &:hover{
    background-color: lighten($color-button-action, 10%);
  }
}
