// Variables
// -----------------

$font-main: "Source Sans Pro", Arial, serif;
$font-button: "Open Sans", "Source Sans Pro", serif;
$font-secondary: Arial, serif;


// Colors
// -----------------

$color-text-main: #22313e;
$color-text-secondary: #55afdc;
$color-title: #22313e;
$color-title-highlighted: #246ab0;
$color-highlight: #0052a4;
$color-primary-menu: #f6f5f3;
$color-highlight-section: #dce9f4;
$color-highlight-section-secondary: rgba(0, 174, 239, 0.1);
$color-breadcrumbs: #777;


$color-button-primary: #0052a4;
$color-button-secondary: #00aeef;
$color-button-success: #39b54a;
$color-button-action:  #eb1c4c;
$color-button-default: #8fa0a6;
$color-button-disabled: #bfc7cc;

$color-pager-active: #f6f5f3;

$color-seo-block: #fbfbfb;
$color-seo-text: #878787;

$color-border-block: #dadada;
$color-delimiter: #bdbdbd;
