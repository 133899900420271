html{
  font-size: 14px;
}

body{
  font-family: $font-main;
}

p,
ul,
h1,
h2,
h3,
h4,
h5,
h6{
  &:first-child{
    margin-top: 0;
  }
}


ul{
  padding-left: 20px;
}

.page-title{
  @include rem(38);
  font-weight: 700;
  color: $color-title;
  margin-bottom: .67em;
  line-height: 1;
}

.h2{
  font-weight: 700;
  color: $color-title;
  @include rem(26);
  margin:.67em 0;

  &_coupe-dashed{
    padding-top: 14px;
    border-top: 1px dashed black;
  }

  &_highlighted{
    color: $color-title-highlighted;
  }

  &:first-child{
    margin-top: 0;
  }

  &_content{
    margin-bottom: 5px;
  }
}

.phone-highlight{
  @include rem(21);
  color: inherit;
}

.text-center{
  text-align: center;
}

.strong{
  font-weight: 700;

  &_block{
    display: block;
  }
}

.h3{
  @include rem(20);
  font-weight: 400;
  margin-bottom: 0.6em;

  &_highlight{
    color: $color-button-primary;
  }
}

.page-card-title{
  font-weight: 400;
  color: black;
  @include rem(32);
  line-height: 1.1;
  margin-bottom: .5em;
}

.card-description{
  line-height: 1.71;
  margin-bottom: 20px;

  p:last-child{
    margin-bottom: 0;
  }
}

.th-label{
  @include rem(16);
}

.content-editor{
  @include rem(16)
  line-height: 1.5;
  margin-bottom: 60px;

  a{
    @extend .common-link;
  }

  ul{
    margin: 0 0 1.5em;
    padding-left: 0;
    list-style-type: none;
  }

  .mb0{
    margin-bottom: 0;
  }

  p{
    margin: 0 0 1.5em;

    ul{
      margin: 0;
    }
  }


  li{
    padding-left: 20px;
    position: relative;
    &:before{
      content: '\2014';
      position: absolute;
      left: 0;
      top: 2px;
      @include rem(14);
    }
  }
}

.slide-title,
.mega-title {
  text-shadow: 1px 1px 0px rgba(0,0,0,.8);
  margin-bottom: .5em;
  line-height: 0.9;
  color: white;
}

.mega-title{
  @include rem(90);
}

.slide-title{
  @include rem(76);
}

.caps-title{
  @include rem(63);
  font-weight: 700;
  text-transform: uppercase;
  text-decoration: none;
  color: $color-highlight;
}




.table-title{
  margin: .67em 0;
  @include rem(32);
  font-weight: 700;
}


.sum-description{
  @include rem(13);
  float: left;
}

.summary-value{
  @include rem(32);
  font-weight: 700;
}

.checkout-attention{
  @include rem(16);
  font-weight: 400;
  color: $color-button-action;
  margin-bottom: 35px;
}

// Links
// ------------------

.common-link{
  color: $color-button-primary;
  text-decoration: underline;
  font-weight: 400;

  &:hover{
    text-decoration: none;
  }
}


.black-link{
  @include rem(17);
  font-weight: 400;
  text-decoration: underline;
  color: $color-text-main;
  margin-right: 25px;
  margin-left: 25px;

  &:hover{
    text-decoration: none;
  }
}
