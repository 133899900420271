$base-font-value: 14;

@mixin rem($value){
  font-size: #{$value}px;
  font-size: ($value / $base-font-value) + rem;
}

.clearfix{
  &:after{
    content: '';
    display: block;
    clear: both;
  }
}
